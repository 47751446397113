import type { IBaseColumnOptions } from 'o365.controls.Grid.BaseColumn.ts';
import BaseColumn from 'o365.controls.Grid.BaseColumn.ts';

export default class BaseColumns {
    // Computed properties
    leftPinnedWidth: number;
    centerWidth: number;
    rightPinnedWidth: number;
    leftColumns: BaseColumn[] = [];
    centerColumns: BaseColumn[] = [];
    rightColumns: BaseColumn[] = [];
    hasPinnedLeftColumns: boolean;
    hasPinnedRightColumns: boolean;

    columns: BaseColumn[] = [];
    initialColumns: IBaseColumnOptions[] = [];
    options: IColumnsOptions;

    protected _columnsWatcher: ColumnsWatcher;

    constructor(options: IColumnsOptions) {
        this.options = options;
    }

    /** Last known column orders */
    private _possibleOrderCache: Map<string, number> = new Map();
    /** Get column by colId */
    getColumn(colId: string) {
        let colIndex = this._possibleOrderCache.get(colId);
        if (colIndex != null) {
            const possibleColumn = this.columns[colIndex]
            if (possibleColumn?.colId === colId) {
                return possibleColumn;
            }
        }
        colIndex = this.columns.findIndex(col => col.colId === colId);
        this._possibleOrderCache.set(colId, colIndex);
        if (colIndex === -1) {
            console.warn(`No column found with colId ${colId}`);
            return null;
        }
        return this.columns[colIndex];
    }

    /** Add column definition */
    addColumn(columnDefinition: IBaseColumnOptions | IBaseColumnOptions[], _index?: number) {
        if (!columnDefinition) { return; }
        const index = _index ?? this.columns.length;

        // TODO: Layouts managing

        const appendCol = (colDef: IBaseColumnOptions, colIndex: number) => {
            const baseColumn = new BaseColumn(colDef);
            this.initialColumns.push(colDef);
            baseColumn.order = colIndex;
            this.columns.push(baseColumn);
        };

        if (Array.isArray(columnDefinition)) {
            columnDefinition.forEach((colDef, colIndex) => {
                appendCol(colDef, index + colIndex);
            });
        } else {
            appendCol(columnDefinition, index);
        }

        this.updateColumnArrays();
        this.updateWidths();
        this.options.onColumnAdded();
    }

    /** Remove column definition */
    removeColumn(colId: string) {
        if (!colId) { return; }
        const indexToRemove = this.columns.findIndex(x => x.colId === colId);
        if (indexToRemove === -1) { return; }

        const getId = (col: any) => col.colId ?? col.field;
        const initialColumnsIndex = this.initialColumns.findIndex(x => getId(x) === colId);
        this.initialColumns.splice(initialColumnsIndex, 1);
        this.columns.splice(indexToRemove, 1);

        this.updateColumnArrays();
        this.updateWidths();
        this.options.onColumnRemoved();
    }

    /** Update computed pinned arrays */
    updateColumnArrays() {
        const leftColumns = [];
        const centerColumns = [];
        const rightColumns = [];
        let hasPinnedLeftColumns = false;
        let hasPinnedRightColumns = false;

        const resort = [];

        this.columns.forEach((col, colIndex) => {
            switch (col.pinned) {
                case 'left':
                    leftColumns.push(col);
                    if (colIndex !== leftColumns.length - 1) {
                        resort.push({ col: col, position: leftColumns.length - 1 });
                    }
                    hasPinnedLeftColumns = true;
                    break;
                case 'right':
                    rightColumns.push(col);
                    hasPinnedRightColumns = true;
                    break;
                default:
                    centerColumns.push(col);
                    break;
            }
        });

        // resort.forEach(sortable => {
        //     this.setColumnOrder(sortable.col, sortable.position, true, false);
        // });

        this.leftColumns = leftColumns;
        this.centerColumns = centerColumns;
        this.rightColumns = rightColumns;
        this.hasPinnedLeftColumns = hasPinnedLeftColumns;
        this.hasPinnedRightColumns = hasPinnedRightColumns;
    }

    /** Update computed widts and left properties */
    updateWidths() {
        let leftSum = 0;
        let rightSum = 0;
        let centerSum = 0;
        this.columns.sort((a, b) => a.order - b.order);
        this.columns.filter(c => !c.hide).forEach((col) => {
            if (col.pinned) {
                if (col.pinned === 'left') {
                    if (col.left !== leftSum) {
                        col.left = leftSum;
                    }
                    leftSum += col.width;
                } else {
                    if (col.left !== rightSum) {
                        col.left = rightSum;
                    }
                    rightSum += col.width;
                }
            } else {
                if (col.left !== centerSum) {
                    col.left = centerSum;
                }
                centerSum += col.width;

            }
        });

        if (this.centerWidth !== centerSum) {
            this.centerWidth = centerSum;
        }
        if (this.leftPinnedWidth !== leftSum) {
            this.leftPinnedWidth = leftSum;
        }
        if (this.rightPinnedWidth !== rightSum) {
            this.rightPinnedWidth = rightSum;
        }
    }

    /** Initialize the column watchers */
    setupWatchers(watchFn: Function, handler: Function) {
        this._columnsWatcher = new ColumnsWatcher(watchFn, handler);
        this.columns.forEach(column => {
            this._columnsWatcher.add(column);
        });
    }


    /** Add watchers for a column */
    addWatcher(column: BaseColumn) {
        if (this._columnsWatcher) { return; }
        this._columnsWatcher.add(column);
    }

    /** Remove watchers for a column */
    removeWatcher(colId: string) {
        if (this._columnsWatcher) { return; }
        this._columnsWatcher.remove(colId);
    }

    /** Remove all column watchers */
    removeWatchers() {
        if (this._columnsWatcher) { return; }
        this._columnsWatcher.removeAll();
    }

}

/** Class generating seperate property watchers for columns */
class ColumnsWatcher {
    private _watch: Function;
    private _handler: Function;
    private columns: { [key: string]: Function[] }
    constructor(watcherFn: Function, handler: Function) {
        this._watch = watcherFn;
        this._handler = handler;
        this.columns = {};
    }

    add(column: BaseColumn) {
        const key = column.colId;
        this.columns[key] = [
            this.generateWatcher(column, 'width'),
            this.generateWatcher(column, 'pinned'),
            this.generateWatcher(column, 'order'),
            this.generateWatcher(column, 'hide'),
        ];
    }

    remove(colId: string) {
        this.columns[colId].forEach(watcher => watcher());
        delete this.columns[colId];
    }

    removeAll() {
        Object.keys(this.columns).forEach(key => {
            this.remove(key);
        });
    }

    generateWatcher(column: BaseColumn, key: string) {
        return this._watch(() => column[key], this._handler);
    }
}

interface IColumnsOptions {
    onColumnAdded: () => void;
    onColumnRemoved: () => void;
};